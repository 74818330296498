<template>
  <div
    class="popup_wrap"
    style="width:750px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.NEWB020P030.003') }}</h1><!-- 첨부파일 확인 -->
      <div class="content_box">
        <h2 class="content_title">{{ setUploadTitle() }}</h2>
        <p class="color_red font_12 mb5" v-if="parentInfo.type === 'F'">{{ $t('msg.NEWB020P030.012') }}</p>
        <component
          v-if="customComponent"
          :is="customComponent"
          ref="dextupload"
          id="dextupload1"
          width="100%"
          height="200px"
          :category="isCategory"
          :file-info="uploadFileList"
          :category-info="msdsList"
        />
      </div>
      <div class="mt10 text_center">
        <a
          class="button blue lg"
          href="#"
          @click.prevent="insertFile()"
        >{{ $t('msg.NEWB020P030.004') }}</a><!-- 저장 -->
      </div>
    </div>
  </div>
</template>

<script>

import commons from '@/api/services/commons'
import DgOogCerti from '@/api/rest/trans/dgOogCerti'
import DEXT5Upload from '@/components/DEXT5Upload'

export default {
  name: 'DgOogCertiPop',
  components: { DEXT5Upload },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          taskCatCd: 'B',
          reqRno: '',
          count: 1,
          type: 'O',
          dgUploadFlag: '',
          bkgNo: '',
          scgAppNo: '',
          blNo: ''
        }
      }
    }
  },
  data () {
    return {
      isCategory: true,

      msdsList: [],

      params: {
        // =============== Booking 등록 parameter
        reqRno: this.parentInfo.reqRno,
        count: this.parentInfo.count,
        type: '',
        dgUpLoadFlag: this.parentInfo.dgUploadFlag,
        bkgNo: this.parentInfo.bkgNo,
        scgAppNo: this.parentInfo.scgAppNo,
        // =============== SR 등록 parameter
        blNo: '',
        blRecvDocKndCd: '',
        recvYn: '',
        // 첨부파일 리스트(실제 입력)
        uploadFileInfos: []
      },

      // Dext Uploader와 연결되어 있는 배열
      uploadFileList: [],
      orgUploadFileList: [],
      customComponent: null
    }
  },
  computed: {
    getCdId: function () {
      const type = this.parentInfo.type
      let cdId = null

      if (type === 'D') cdId = 'VS038'
      if (type === 'O') cdId = '10064'
      if (type === 'F') cdId = 'VS040'

      return cdId
    }
  },
  created () {
    //setTimeout(() => this.initPopup(), 1000)
    this.initPopup()
  },
  methods: {
    async initPopup () {
      this.customComponent = null

      const taskCatCd = this.parentInfo.taskCatCd

      this.isCategory = this.parentInfo.taskCatCd === 'B' ? this.isCategory : !this.isCategory

      // 업무 구분 코드에 따라 분기( B=>Booking 등록 / S=>SR 등록 )
      if (taskCatCd === 'B') {
        await this.getCategoryList()
        if (this.parentInfo.reqRno !== '') {
          await this.getCertiFileListForBooking()
        }
      } else if (taskCatCd === 'S' && this.parentInfo.blNo !== '') {
        await this.getCertiFileListForSR()
      }

      this.orgUploadFileList = this.uploadFileList.slice()

      this.customComponent = 'DEXT5Upload'
    },
    async getCategoryList () {
      await commons.getCommonsCodesCommon([this.getCdId]).then(response => {
          response[this.getCdId].forEach(element => {
            this.msdsList.push(
              {
                category: element.cdNm,
                value: element.cd
              }
            )
          })
      })
    },
    // 파일 조회(Booking 등록)
    async getCertiFileListForBooking () {
      await DgOogCerti.getCertiFileListForBooking(this.parentInfo).then(response => {
        let uri = 'http://trans.ekmtc:9000/trans/trans/file-download'
        let paramType = 'DG_OOG_CERTI_BK'

        if (process.env.VUE_APP_MODE === 'LOCAL') {
          uri = 'http://localhost:9006/trans/file-download'
        }

        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.dgOogCertiFileInfoList

          if (this.parentInfo.type === 'F') {
            fileList = response.data.flexibagCertiFileInfoList
            paramType = 'FLEXI_BAG_CERTI_BK'
          }

          if (fileList !== undefined) {
            fileList.forEach(file => {
              const category = this.parentInfo.type === 'D' ? file.dgAtchKndCd : (this.parentInfo.type === 'F' ? file.fbCatCd : file.oogCatCd)

              this.uploadFileList.push(
                {
                  guid: file.reqRno + '_' + file.atchFileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: uri + '?reqRno=' + file.reqRno + '&atchFileSeq=' + file.atchFileSeq + '&type=' + paramType,
                  category: category,
                  atchFileSeq: file.atchFileSeq
                }
              )
            })
          }
          // this.$refs.dextupload.setFileInfo(this.uploadFileList)
        }
      })
    },
    // 파일 조회(SR 등록)
    async getCertiFileListForSR () {
      await DgOogCerti.getCertiFileListForSR(this.parentInfo).then(response => {
        const fileList = response.data.dgOogCertiFileInfoList

        if (fileList !== undefined) {
          fileList.forEach(file => {
            this.uploadFileList.push(
              {
                guid: file.blNo + '_' + file.fileSeq,
                originalName: file.fileNm,
                size: file.fileSz,
                fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?blNo=' + file.blNo + '&fileSeq=' + file.fileSeq + '&type=DG_CERTI_SR',
                fileSeq: file.fileSeq
              }
            )
          })
        }
      })
    },
    validationCategory () {
      let isOk = true

      // 파일리스트 카테고리 선택 여부 검사
      this.uploadFileList.forEach(element => {
        if (element.category === undefined || element.category === '') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.NEWB020P030.006'))
          isOk = false
          return false
        }
      })

      return isOk
    },
    // 저장 or 수정
    async insertFile () {
      if (this.parentInfo.taskCatCd === 'S' || this.validationCategory()) {
        const TH = this
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.NEWB020P030.008'), useConfirmBtn: true })) {
          await TH.onConfirmInsert()
        }
      }
    },
    // 저장 Confirm '확인' 클릭 시 콜백 메소드
    async onConfirmInsert () {
      const taskCatCd = this.parentInfo.taskCatCd

      // 상위 컴포넌트에서 가져온 데이터를 실제 입력할 parameter에 담는다.
      this.params = { ...this.params, ...this.parentInfo }
      // 실제 입력할 파일리스트를 담는다.
      this.params.uploadFileInfos = this.uploadFileList

      // 새로 추가된 파일은 json에 'newFlag' 값을 부여해준다. ('new'는 뒷단에서 예약어 이므로 새로 추가)
      this.params.uploadFileInfos.map((item) => (item.newFlag = item.new))

      // 기존 파일은 fileId에 다운로드 링크가 들어 있어서
      // 뒷단에서 Data binding 할 때, 오류가 나서 값을 비워줘야 한다.
      this.params.uploadFileInfos.forEach(element => {
        if (element.newFlag === undefined || element.newFlag === '') {
          element.fileId = ''
        }
      })

      if (taskCatCd === 'B') {
        // 파일 저장(Booking 등록)
        await DgOogCerti.popDgOogUpload(this.params).then(response => {
          let result = response.data
          if (response.headers.respcode === 'C0000' && result !== undefined && result.resultCode === 'SUCC') {
            const TH = this

            result.uploadFileInfos = this.params.uploadFileInfos

            TH.$ekmtcCommon.alertCallback(TH.$t('msg.NEWB020P030.009'), function () {
              TH.$emit('selectFunc', result)
            })
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.NEWB020P030.010'))
          }
        })
      } else {
        // 파일 저장(SR 등록)
        await DgOogCerti.popDgCertiUpload(this.params).then(response => {
          if (response.headers.respcode === 'C0000') {
            const TH = this
            TH.$ekmtcCommon.alertCallback(TH.$t('msg.NEWB020P030.009'), function () {
              TH.$emit('selectFunc')
            })
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.NEWB020P030.010'))
          }
        })
      }
    },
    setUploadTitle () {
      let title = ''
      const parentInfo = this.parentInfo
      const type = parentInfo.type

      if (type === 'F') {
        title = this.$t('msg.NEWB020P030.011')
      } else {
        if (parentInfo.taskCatCd === 'B') {
          title = this.$t('msg.NEWB020P030.001')
        } else {
          title = this.$t('msg.NEWB020P030.002')
        }
      }

      return title
    }
  }
}
</script>
